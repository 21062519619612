import React from 'react'
import {
    Box,
    Text,
    Flex
} from 'rebass'

const Header = ({ page, updateAppStatePage }) => {
    return (
        <div>
            <Flex flexWrap='wrap'>
                <NavItem 
                    label="Home"
                    currentPage={page}
                    pageName="home"
                    onClick={updateAppStatePage}
                />
                <NavItem 
                    label="About"
                    currentPage={page}
                    pageName="about"
                    onClick={updateAppStatePage}
                />
                <NavItem 
                    label="Menu"
                    currentPage={page}
                    pageName="menu"
                    onClick={updateAppStatePage}
                />
            </Flex>
        </div>
    )
}

// Create a reusable NavItem component to reduce repetition
const NavItem = ({ label, currentPage, pageName, onClick }) => (
    <Box width={[1, 1/3]} p={3}>
        <Text 
            css="cursor: pointer;" 
            fontSize='200%' 
            fontWeight={currentPage === pageName ? "bold" : "normal"}
            onClick={() => onClick(pageName)}
        >
            {label}
        </Text>
    </Box>
)

export default Header
