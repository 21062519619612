import React from 'react'
import {
    Box,
    Text,
    Flex
} from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFireAlt, faEgg, faDrumstickBite } from '@fortawesome/free-solid-svg-icons'
import MenuData from './MenuData'
import MenuItem from './MenuItem'

const FoodMenu = () => {
    const MenuSection = ({ title, category, subtitle, additions }) => (
        <>
            <Box height='2px' backgroundColor='black' />
            <Text px={3} fontWeight='bold' fontSize='175%' textAlign='left'>
                {title}
            </Text>
            <Box height='2px' backgroundColor='black' />
            {subtitle && (
                <Box px={3} width={[1, 1/2]}>
                    <Text textAlign='left' fontStyle='italic'>
                        {subtitle}
                    </Text>
                </Box>
            )}
            {additions}
            <Flex py={3} flexWrap='wrap' textAlign='center'>
                {MenuData.map((item) => (
                    item.category === category ? 
                        <Box key={item.name} px={3} pb={3} width={[1, 1/2]} textAlign='left'>
                            <MenuItem item={item} />
                        </Box> 
                    : null
                ))}
            </Flex>
        </>
    )

    const AdditionItem = ({ label, price }) => (
        <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
            <Text textAlign='left' fontWeight='bold' fontStyle='italic'>{label}</Text>
            <Text pr={[3, 6]} fontWeight='bold'>{price}</Text>
        </Flex>
    )

    return (
        <div>
            <Box textAlign='center'>
                <Box width='90%' margin='auto'>
                    <Flex flexDirection='column' textAlign='center'>
                        <MenuSection 
                            title="ENTREES"
                            category="Entrees"
                            subtitle="All entrees are offered with a choice of our roasted garlic mashed potatoes, french fries or today's vegetable as well as a choice of garden greens salad or one of our handmade soups."
                            additions={<AdditionItem label="Substitute a Caesar salad" price="4" />}
                        />

                        <MenuSection 
                            title="ITALIAN FAVORITES"
                            category="Italian Favorites"
                            subtitle="All Italian favorites include a choice of garden greens salad or one of our handmade soups."
                            additions={
                                <>
                                    <AdditionItem label="Substitute a Caesar salad" price="4" />
                                    <Text px={3} textAlign='left' fontWeight='bold' fontStyle='italic'>
                                        Add the following to the above favorites:
                                    </Text>
                                    <AdditionItem label="Grilled Italian Sausage" price="7" />
                                    <AdditionItem label="Grilled Chicken" price="7" />
                                    <AdditionItem label="Meatballs" price="7" />
                                    <AdditionItem label="Pesto Shrimp" price="15" />
                                    <AdditionItem label="Grilled Salmon" price="20" />
                                    <AdditionItem label="Grilled Lobster Tail" price="20" />
                                </>
                            }
                        />

                        <MenuSection 
                            title="APPETIZERS"
                            category="Appetizers"
                        />

                        <Flex flexWrap='wrap' textAlign='center'>
                            <Box width={[1, 1/2]}>
                                <MenuSection 
                                    title="SALADS"
                                    category="Salads"
                                />
                            </Box>

                            <Box width={[1, 1/2]}>
                                <MenuSection 
                                    title="SOUPS"
                                    category="Soups"
                                    additions={
                                        <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                            <Text fontWeight='bold' textAlign='left'>Cup  8</Text>
                                            <Text fontWeight='bold' pr={[3, 6]}>Bowl  10</Text>
                                        </Flex>
                                    }
                                />
                            </Box>
                        </Flex>

                        <MenuSection 
                            title="DESSERTS"
                            category="Desserts"
                            additions={
                                <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                    <Text fontWeight='bold' textAlign='left'>ALL HOUSEMADE DESSERTS</Text>
                                    <Text fontWeight='bold' pr={[3, 6]}>9</Text>
                                </Flex>
                            }
                        />

                        <Box height='2px' backgroundColor='black' />

                        <Text><FontAwesomeIcon icon={faDrumstickBite} /> consuming raw or undercooked (rare, medium-rare, or medium) foods may increase your risk of food-borne illness</Text>
                        <Text><FontAwesomeIcon icon={faEgg} /> contains raw egg</Text>
                        <Text><FontAwesomeIcon icon={faFireAlt} /> Spicy</Text>

                        <Text fontSize={1}>An 18% service charge will be added to all parties of six or more.</Text>
                        <Text fontSize={1}>Carry-in food and beverage prohibited.</Text>
                        <Text fontSize={1}>Menu and prices are subject to change.</Text>

                        <Box height='2px' backgroundColor='black' />
                    </Flex>
                </Box>
            </Box>
        </div>
    )
}

export default FoodMenu