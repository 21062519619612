import React from 'react'
import { Home, Menu, About } from '../index'

const Main = ({ page }) => {
    const getPage = () => {
        if (page === "menu") {
            return <Menu />
        }
        else if (page === "about") {
            return <About />
        } 
        return <Home />
    }

    return (
        <div>
            {getPage()}
        </div>
    )
}

export default Main
