import React from 'react'
import {
    Box,
    Text,
    Flex
} from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFireAlt, faEgg, faDrumstickBite } from '@fortawesome/free-solid-svg-icons'

const MenuItem = ({ item }) => {
    return (
        <div>
            <Flex justifyContent='space-between'>
                <Flex>
                    <Box>
                        <Text fontWeight='bold'>{item.name.toUpperCase()}</Text>
                    </Box>

                    {item.warnRaw &&
                        <Box px={2}>
                            <FontAwesomeIcon icon={faDrumstickBite} />
                        </Box>
                    }
                    {item.warnEgg &&
                        <Box px={2}>
                            <FontAwesomeIcon icon={faEgg} />
                        </Box>
                    }
                    {item.warnSpicy &&
                        <Box px={2}>
                            <FontAwesomeIcon icon={faFireAlt} />
                        </Box>
                    }
                </Flex>

                <Text pr={[3, 6]} fontWeight='bold'>{item.price}</Text>
            </Flex>

            <Box>
                <Text>{item.description}</Text>
            </Box>
        </div>
    )
}

export default MenuItem