import React, { useState } from 'react'
import { filter } from 'lodash/collection'
import { get } from 'lodash/object'
import './App.css'
import { Helmet } from 'react-helmet'
import images from './res/images'
import {
  Box,
  Card,
  Image,
  Heading,
  Text,
  Flex
} from 'rebass'
import { Main, Header, Footer } from './components/index'

const App = () => {
  const [page, setPage] = useState("home")
  const [menuType, setMenuType] = useState("food")

  return (
    <Flex 
      minHeight='100vh' 
      textAlign='center' 
      flexDirection='column' 
      justifyContent='space-between'
    >
      <Box by={2}>
        <Header page={page} updateAppStatePage={setPage} />
      </Box>
      <Box>
        <Main page={page} />
      </Box>
      <Box marginTop={page === 'menu' ? 'auto' : 'initial'}>
        <Footer />
      </Box>
    </Flex>
  )
}

export default App
