import React, { useState } from 'react'
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex
  } from 'rebass';
import MenuData from './MenuData';
import FoodMenu from './FoodMenu';
import DrinkMenu from './DrinkMenu';

const Menu = () => {
    const [menuType, setMenuType] = useState("food")

    const MenuHeader = () => (
        <Flex flexWrap='wrap' textAlign='top'>
            <Box width={[1, 1/2]} p={3} textAlign={['center', 'right']}>
                <Text 
                    css="cursor: pointer;"
                    fontSize='175%'
                    fontWeight={menuType === "food" ? "bold" : "normal"}
                    onClick={() => setMenuType("food")}
                >
                    Food
                </Text>
            </Box>
            {/* <Box width={[1, 1/2]} p={3} textAlign={['center', 'left']}>
                <Text 
                    css="cursor: pointer;"
                    fontSize='175%'
                    fontWeight={menuType === "drink" ? "bold" : "normal"}
                    onClick={() => setMenuType("drink")}
                >
                    Drink
                </Text>
            </Box> */}
        </Flex>
    )

    const MenuPage = () => (
        // Uncomment and modify if implementing drink menu
        // menuType === 'food' ? <FoodMenu /> : <DrinkMenu />
        <FoodMenu />
    )

    return (
        <div>
            <MenuHeader />
            <MenuPage />
        </div>
    )
}

export default Menu
