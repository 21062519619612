import React, { useState, useEffect } from 'react'
import images from '../../res/images'
import {
    Box,
    Image,
    Text
} from 'rebass'

const About = () => {
    const [familyImageHeight, setFamilyImageHeight] = useState(window.innerHeight * 0.75)

    useEffect(() => {
        const handleResize = () => {
            setFamilyImageHeight(window.innerHeight * 0.75)
        }

        window.addEventListener('resize', handleResize)
        
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <div>
            <Box width={[1, .8]} textAlign='center' margin='auto' padding={10}>
                <Image 
                    sx={{'object-fit': 'cover'}} 
                    height={familyImageHeight} 
                    src={images.family} 
                />
            </Box>
            <Box textAlign='center'>
                <Text width={[.8,.5]} margin='auto'>
                    Trattoria Dal Santo offers diners a unique experience of Northern Italian cooking along with fine wines and beer served in a comfortable, casual atmosphere.  
                    Come visit Sturgeon Bay's romantic, moderately priced, Italian restaurant for the ambiance, for the food, for the wine, and for that little touch of Italy in Door County.
                </Text>
            </Box>
        </div>
    )
}

export default About
