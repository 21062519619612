import React, { useState, useEffect } from 'react'
import images from '../../res/images'
import {
    Box,
    Image,
    Text,
    Link
} from 'rebass'

const Home = () => {
    const [splashImageHeight, setSplashImageHeight] = useState(window.innerHeight * 0.6)
    const [qrImageHeight, setQrImageHeight] = useState(window.innerHeight * 0.3)

    useEffect(() => {
        const handleResize = () => {
            setSplashImageHeight(window.innerHeight * 0.6)
            setQrImageHeight(window.innerHeight * 0.3)
        }

        window.addEventListener('resize', handleResize)
        
        // Cleanup listener on component unmount
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <div>
            <Box width={[1, .8]} textAlign='center' margin='auto' padding={10}>
                <Image 
                    sx={{'object-fit': 'cover'}} 
                    height={splashImageHeight} 
                    src={images.frontDoor} 
                />
            </Box>
            <Box width={[1, .8]} textAlign='center' margin='auto' padding={10}>
                <Link href='https://forms.gle/4pFJ6LU2AqVHCG7UA'>
                    <Image 
                        sx={{
                            'object-fit': 'contain',
                            'max-width': '400px',
                            'width': '100%'
                        }} 
                        height={qrImageHeight} 
                        src={images.hiringQrSticker} 
                    />
                </Link>
            </Box>
        </div>
    )
}

export default Home
